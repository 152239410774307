:root {
  --primary: #EF7B45;
  --secondary: #CDEDF6;
  --tertiary: #5EB1BF;
  --dark: #042A2B;
  --amplify-primary-color: var(--dark);
}

body {
  background: var(--secondary);
}

.App {
  display: grid;
  height: 100%;
  grid-template-areas: 
    "nav content content";
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  color: var(--dark);
  padding: 10px;
}

@media screen and (max-width: 800px) {
  .App {
    grid-template-areas:
      "nav"
      "content";
    grid-template-columns: 1fr;
  }

  nav {
    grid-template-columns: 50% 50%;
  }

  .posts {
    justify-content: stretch;
    align-items: stretch;
  }
}

nav {
  grid-area: nav;
  margin: 10px;
  display: grid;
  align-items: stretch;
  align-self: start;
  transition: ease .15s;
  gap: 10px;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  margin-right: 10px;
}

.username {
  transition: all .15s ease;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
}

.user-button:hover .username {
  opacity: 0;
  max-width: 0;
}

.log-out {
  opacity: 0;
  max-width: 0;
  white-space: nowrap;
  transition: all .15s ease;
}

.user-button:hover .log-out {
  opacity: 1;
  max-width: 200px;
}

.posts {
  margin-top: 10px;
  grid-area: content;
  display: grid;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 20px;
  background: var(--dark);
  border-radius: 100px;
  color: var(--secondary);
  outline: hidden;
  cursor: pointer;
  border: none;
  transition: ease .15s;
  font-size: 16pt;
  font-weight: bold;
}

button:hover {
  background: var(--tertiary);
  color: var(--dark);
}

.post {
  border: 1px solid var(--dark);
  border-radius: 25px;
  padding: 20px;
  min-width: 350px;
}

.content {
  margin: 10px 0;
}

h1 {
  margin-top: 0;
}

.authenticator {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  right: 0;
  background-color: var(--dark);
}

.close-login {
  position: fixed;
  right: 10px;
  top: 10px;
}